header, footer{
    position: fixed;
    padding: 0 8vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 3;
    padding-top: 40px;
    mix-blend-mode: difference;
    background-color: none;
    a, p{
        color: white !important;
        margin: 10px 0;
        font-size: 12px;
        @media (min-width: 2000px) {
            font-size: 16px;
        } 
    }
    p{
        opacity: 0.5;
        line-height: 0;
    }
    nav{
        display: flex;
        align-items: center;
        a, .theme{
            margin-left: 20px;
        }
        .theme{
            cursor: pointer;
            width: 18px;
            height: 18px;
            background-image: url('./../img/Icons/sun.svg');
            background-size: cover;
            .darkMode & {
                background-image: url('./../img/Icons/moon.svg');
            }
        }
    }
    @media (min-width: 2000px) {
        nav{
            a, .theme{
                margin-left: 0;
            }
            .theme{
                width: 24px;
                height: 24px;
            }
            a{
                margin: 0 24px 0 0!important;
            }
        }
    } 
    @media (max-width: 900px) {
        justify-content: center;
        p, h4{
            display: none;
        }
        h4{
            margin-bottom: 18px;
        }
        nav{
            a{
                margin: 0 20px !important;
            }
        }
    } 
}


header{
    top: 0;
    left: 0;
}

footer{
    left: 0;
    bottom: 40px;
    nav{
        display: flex;
        align-items: center;
        a{
            margin-left: 0px;
            margin-right: 22px;
            img{
                width: 24px;
            }
            @media (min-width: 2000px) {
                margin-right: 26px;
            } 
        }
    }
}


.thumbnail{
    aspect-ratio: 1/1;
    cursor: pointer;
    border: solid 4px rgb(255, 255, 255);
    overflow: hidden;
    .darkMode & {
        border: solid 4px rgba(28, 28, 28, 0.941);
    }
    img{
        width: 100%;
        // height: 100%;
        object-fit: cover;
        animation-name: fadeAnimation;
        animation-duration: 0.8s;
        transition: scale 0.4s;
        &:hover{
            scale: 1.05;
        }
    }
    .infos{
        margin-top: 4px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        p{
            font-size: 12px;
            margin: 0;
            &:nth-child(2){
                text-align: right;
            }
            @media (min-width: 2000px) {
                font-size: 16px;
            } 
        }
    }
}

.custom-bg{
    width: 100vw;
    height: 100vh;
    background:url('./../img/background.jpg');
    // background:url('./../img/Paysage/cover.jpg');
    background-size: cover;
    animation-name: zoomIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    .darkMode &{
        background:url('./../img/background.jpg');
        background-size: cover;
        animation-name: zoomIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    div.black_filter{
        z-index: 2;
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%); 
    }
}

.intro{
    width: 100Vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 30vw;
        @media (max-width: 900px) {
            width: 60vw;
        }
    }
}

section.gallery{
    margin-top: 20vh;
    padding: 0 8vw;

    .desc{
        mix-blend-mode: difference;
        display: flex;
        margin-bottom: 80px;
        align-items: flex-end;
        p{
            width: 30%;
            margin: 0;
            margin-bottom: 10px;
        }
        h1{
            margin-right: 80px;
        }
        @media (max-width: 1200px) {
            p{
                width: 60%;
            }
        } 
        @media (max-width: 900px) {
            h1, p{
                width: 100%;
            }
            h1{
                margin-bottom: 16px;
            }
            flex-wrap: wrap;
            height: auto;
            margin-bottom: 64px;
        } 
    }
    .categories{
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap:16px;
        .category{
            .thumbnail{
                aspect-ratio: 1/1;
                img{
                    height: 100%;
                }
            }
        }
        @media (max-width: 1200px) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 16px;
        } 
        @media (max-width: 700px) {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 16px;
        } 
    }
}

section.highlights{
    margin-top: 15vh;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 8vw;
    .thumbnail{
        aspect-ratio: auto;
        overflow: hidden;
        img{
            height: 100%;
        }
    }

    @media (max-width: 700px) {
        margin-top: 10vh;
        padding-bottom: 10vh;
    } 
    h1{
        width: 100%;
        margin: 80px 0;
        @media (max-width: 700px) {
            font-size: 32px;
            margin: 60px 0;
        }   
    }
    .margin_bottom{
        display: flex;
        justify-content: flex-start;
        padding-bottom: 40vh;
    }
    .margin_top{
        padding-top: 40vh;
        display: flex;
        justify-content: flex-end;
    }
    .margin_top, .margin_bottom{
        width: 50%;
        .thumbnail{
            width: 80%;
            .infos{
                display: none;
            }
        }
        @media (max-width: 900px) {
            width: 100%;
            padding: 0;
            margin-bottom: 60px;
            .thumbnail{
                width: 100%;
                aspect-ratio: auto;
            }
        }      
        @media (max-width: 900px) {
            margin-bottom: 24px;
        }      
    }
}

div.grid_filter{
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 16px;
    img{
        cursor: pointer;
        width: 20px;
        opacity: 0.4;
        transition: opacity 0.3s;
        &.active, &:hover{
            opacity: 1;
        }
    }
    @media (max-width: 700px) {
        display: none;
    } 
}

div.heading{
    padding: 0 8vw;
    width: 100%;
    margin-top: 20vh;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding-bottom: 64px;
    mix-blend-mode: difference;
    nav{
        display: flex;
        align-items: flex-end;
        gap: 24px;
        flex-wrap: wrap;
        a{
            font-size: 12px;
            margin: 0;
            padding: 0;
            opacity: 0.4;
            width: auto;
            margin-bottom: 7px;
            text-transform:capitalize;
            transition: opacity 0.4s;
            color: white;
            @media (max-width: 700px) {
                margin-bottom: 4px;
            } 
            &:hover{
                opacity: 1;
            }
            &.big{
                font-size: 40px;
                opacity: 1;
                margin-bottom: 0;
                text-transform: inherit;
                @media (max-width: 700px) {
                    font-size: 32px;
                    width: 80vw;
                } 
            }
        }
        @media (max-width: 700px) {
            gap: 16px;
        } 
    }
    @media (max-width: 700px) {
        margin-top: 16vh;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding-bottom: 56px;
    } 
}

section.category{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 24px;
    padding: 0 8vw;
    margin-bottom: 20vh;
    @media (max-width: 700px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    } 
    &.size1{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        @media (max-width: 700px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        } 
    }
    &.size2{
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 16px;
        @media (max-width: 700px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        } 
    }
    &.size3{
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 8px;
        @media (max-width: 700px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        } 
    }
        .thumbnail{
            .infos{
                display: none;
            }
            img{
                height: 100%;
         }
    }
    @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    } 
    @media (max-width: 700px) {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    } 
}

section.shop{
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        font-size: 42px;
        @media (min-width: 2000px) {
            font-size: 64px;
        } 
    }
    .thumbnail{
        margin-top: 24px;
        width: 20%;
        height: auto;
        @media (max-width: 800px) {
            width: 50%;
        } 
    }
}

section.modale{
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    position: fixed; 
    transition: background-color 0.8s;
    z-index: 10;
    img{
        max-height: 80vh;
        max-width: 80vw;
        object-fit: contain;
        z-index: 3;
    }
    button{
        position: absolute;
        top: 5vh;
        right: 5vh;
        background: none;
        border: solid 1px rgba(255, 255, 255, 0.5);
        padding: 12px 32px;
        color: white;
        transition: background-color 0.6s;
        &:hover{
            background-color: white;
            color: black;
        }
    }
    &.hidden{
        display: none;
    }
    &.show{
        opacity: 1;
    }
}